import React from "react";
import "./AboutUs.scss";
import overview from "../../assets/icons/overview.svg";
import mission from "../../assets/icons/mission.svg";
import services from "../../assets/icons/services.svg";

import { useSelector } from "react-redux";

const AboutUs = () => {
  const isToggledFromStore = useSelector((state) => state.isToggled);

  return (
    <div className="aboutus-container">
      <div className="aboutus-card">
        <div className={`aboutus-icon ${!isToggledFromStore? "white-shadow" : ""}`}>
          <img src={overview} alt="overview" />
        </div>
        <div className="flex flex-col justify-center items-center gap-[0.8rem]">
          <div className="title">Company Overview</div>
          <div className="text-center">
            The company Mehr operates under an official license granted by the
            Turkish Chamber of Commerce, ensuring compliance with all regulatory
            and...
          </div>
        </div>
        <button>
          <a href="/about-us">Read More</a>
        </button>
      </div>
      <div className="aboutus-card">
        <div className={`aboutus-icon ${!isToggledFromStore? "white-shadow" : ""}`}>
          <img src={mission} alt="mission" />
        </div>
        <div className="flex flex-col justify-center items-center gap-[0.8rem]">
          <div className="title">Our Mission and Values</div>
          <div className="text-center">
            Our mission is to provide currency exchange services with the
            highest standards of security, transparency, and speed, ensuring a
            seamless and...
          </div>
        </div>
        <button>
          <a href="/about-us">Read More</a>
        </button>
      </div>
      <div className="aboutus-card">
        <div className={`aboutus-icon ${!isToggledFromStore? "white-shadow" : ""}`}>
          <img src={services} alt="services" />
        </div>
        <div className="flex flex-col justify-center items-center gap-[0.8rem]">
          <div className="title">Our Services</div>
          <div className="text-center">
            We offer a wide range of services in displaying real-time rates for
            gold, currencies, and cryptocurrencies. These services include...
          </div>
        </div>
        <button>
          <a href="/about-us">Read More</a>
        </button>
      </div>
    </div>
  );
};

export default AboutUs;
