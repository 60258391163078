import React, { useState } from "react";
import Modal from "../Modal";
import Chart from "../Chart";
import up from "../../assets/icons/up.svg";
import down from "../../assets/icons/down.svg";
import diagram from "../../assets/icons/diagram.svg";
import darkDiagram from "../../assets/icons/dark-diagram.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const calculatePercentageDifference = (currentPrice, previousPrice) => {
  if (previousPrice === 0 || !currentPrice || !previousPrice) return 0;
  return (((currentPrice - previousPrice) / previousPrice) * 100).toFixed(2);
};

const formatTime = (date) =>
  date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

const formatDate = (date) =>
  date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });

const CurrencyTomanIstanbulTable = ({ tomanCurrencies, currentTime }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [USDRates, setUSDRates] = useState({});
  const isToggledFromStore = useSelector((state) => state.isToggled);

  const navigate = useNavigate();

  const handleDiagramClick = (item) => {
    setChartData(item);
    // setIsModalOpen(true);
    navigate(`/chart/currency-in-toman/istanbul/${item.id}`, {
      state: { data: tomanCurrencies, item },
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setChartData(null);
  };

  return (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(currentTime)} -</div>
        <div className="title-5">{formatTime(currentTime)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Istanbul Currency
                </div>
                <div className="g">
                  <div className="title-5">{formatDate(currentTime)} -</div>
                  <div className="title-5">{formatTime(currentTime)}</div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              <>
                {Array.isArray(tomanCurrencies) &&
                  tomanCurrencies
                    .filter((item) => item.id !== 6)
                    .map((item, index) => (
                      <div className="item" key={`${item.name}-${index}`}>
                        <div className="g-3">
                          <div className="title-7">
                            {item.name}
                            <span className=""> /IRR</span>
                          </div>
                          {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                        </div>
                        <div className="g-2">
                          <div className="text">
                            {item?.istanbulBuyRateChange.includes("-")
                              ? item.istanbulBuyPrice <=
                                item?.istanbulBuyRateChange
                                ? item.istanbulBuyPrice
                                : (
                                    item?.istanbulBuyPrice -
                                    parseFloat(
                                      item?.istanbulBuyRateChange.replace(
                                        "-",
                                        ""
                                      )
                                    )
                                  ).toLocaleString()
                              : (
                                  item?.istanbulBuyPrice +
                                  parseFloat(
                                    item?.istanbulBuyRateChange.replace("-", "")
                                  )
                                ).toLocaleString()}
                          </div>
                          <div className="text">
                            {item.istanbulSellRateChange.includes("-")
                              ? item.istanbulSellPrice <=
                                item?.istanbulSellRateChange
                                ? item.istanbulSellPrice
                                : (
                                    item?.istanbulSellPrice -
                                    parseFloat(
                                      item?.istanbulSellRateChange.replace(
                                        "-",
                                        ""
                                      )
                                    )
                                  ).toLocaleString()
                              : (
                                  item?.istanbulSellPrice +
                                  parseFloat(
                                    item?.istanbulSellRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()}
                          </div>
                          <div className="text-icon">
                            <img
                              src={
                                item.istanbulSellPrice >
                                item.istanbulYesterdaySellPrice
                                  ? up
                                  : down
                              }
                              alt={
                                item.istanbulSellPrice >
                                item.istanbulYesterdaySellPrice
                                  ? "Uptrend"
                                  : "Downtrend"
                              }
                            />
                            <div
                              className={`title-9 ${
                                item.istanbulSellPrice >
                                item.istanbulYesterdaySellPrice
                                  ? "green-rgba"
                                  : "red-rgba"
                              }`}
                            >
                              {item?.change !== "0"
                                ? item?.change
                                : calculatePercentageDifference(
                                    item.istanbulSellPrice,
                                    item.istanbulYesterdaySellPrice
                                  )}
                              %
                            </div>
                          </div>
                        </div>
                        <img
                          className="ml-2"
                          src={!isToggledFromStore ? darkDiagram : diagram}
                          alt="Trend diagram"
                          onClick={() => handleDiagramClick(item)}
                        />
                      </div>
                    ))}

                <div
                  className="item"
                  key={`${tomanCurrencies[5]?.name} + "Istanbul"`}
                >
                  <div className="g-3">
                    <div className="title-7">
                      {tomanCurrencies[5]?.name}
                      <span className="">
                        {" "}
                        {tomanCurrencies[5]?.id === 6 ? "/IRR" : "/USD"}{" "}
                      </span>
                    </div>
                    {/* <div className="title-8">{formatTime(tomanCurrencies[5]?.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {USDRates["USD" + tomanCurrencies[5]?.symbol]?.toFixed(
                        2
                      ) ||
                        tomanCurrencies[5]?.istanbulBuyPrice.toLocaleString()}
                    </div>
                    <div className="text">
                      {tomanCurrencies[5]?.istanbulSellPrice.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={
                          tomanCurrencies[5]?.istanbulSellPrice >
                          tomanCurrencies[5]?.istanbulPreSellPrice
                            ? up
                            : down
                        }
                        alt={
                          tomanCurrencies[5]?.istanbulSellPrice >
                          tomanCurrencies[5]?.istanbulPreSellPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          tomanCurrencies[5]?.istanbulSellPrice >
                          tomanCurrencies[5]?.istanbulPreSellPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {calculatePercentageDifference(
                          tomanCurrencies[5]?.istanbulSellPrice,
                          tomanCurrencies[5]?.istanbulPreSellPrice
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                    onClick={() => handleDiagramClick(tomanCurrencies[5])}
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {chartData && <Chart data={chartData} type="currencyInToman" />}
      </Modal>
    </div>
  );
};

export default CurrencyTomanIstanbulTable;
