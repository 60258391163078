import React from "react";
import Modal from "../Modal";
import Chart from "../Chart";
import up from "../../assets/icons/up.svg";
import down from "../../assets/icons/down.svg";
import diagram from "../../assets/icons/diagram.svg";
import darkDiagram from "../../assets/icons/dark-diagram.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const calculatePercentageDifference = (currentPrice, previousPrice) => {
  if (previousPrice === 0 || !currentPrice || !previousPrice) return 0;
  return (((currentPrice - previousPrice) / previousPrice) * 100).toFixed(2);
};

const formatTime = (date) =>
  date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

const formatDate = (date) =>
  date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });

const CurrencyDollarReverseTable = ({ currencies, currentTime }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [chartData, setChartData] = React.useState(null);
  const isToggledFromStore = useSelector((state) => state.isToggled);

  const navigate = useNavigate();

  const handleDiagramClick = (item) => {
    setChartData(item);
    // setIsModalOpen(true);
    navigate(`/chart/currency/USD/${item.id}`, {
      state: { data: currencies, item },
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setChartData(null);
  };

  return (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(currentTime)} -</div>
        <div className="title-5">{formatTime(currentTime)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  USD / Currency
                </div>
                <div className="g">
                  <div className="title-5">{formatDate(currentTime)} -</div>
                  <div className="title-5">{formatTime(currentTime)}</div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              {Array.isArray(currencies) &&
                currencies
                  .filter((item) => item.id !== 1)
                  .map((item, index) => (
                    <div className="item" key={`${item.name}-${index}`}>
                      <div className="g-3">
                        <div className="title-7">
                          <span className="">USD / </span>
                          {item.name}
                        </div>
                        {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {item?.USDBuyRateChange.includes("-")
                            ? item.USDBuyPrice <= item?.USDBuyRateChange
                              ? 1 / item.USDBuyPrice
                              : (
                                  1 /
                                  (item?.USDBuyPrice -
                                    parseFloat(
                                      item?.USDBuyRateChange.replace("-", "")
                                    ))
                                ).toLocaleString()
                            : (
                                1 /
                                (item?.USDBuyPrice +
                                  parseFloat(
                                    item?.USDBuyRateChange.replace("-", "")
                                  ))
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {item.USDSellRateChange.includes("-")
                            ? item.USDSellPrice <= item?.USDSellRateChange
                              ? 1 / item.USDSellPrice
                              : (
                                  1 /
                                  (item?.USDSellPrice -
                                    parseFloat(
                                      item?.USDSellRateChange.replace("-", "")
                                    ))
                                ).toLocaleString()
                            : (
                                1 /
                                (item?.USDSellPrice +
                                  parseFloat(
                                    item?.USDSellRateChange.replace("-", "")
                                  ))
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              item?.change !== "0"
                                ? item?.change.includes("-")
                                  ? down
                                  : up
                                : item.USDSellPrice < item.USDPreSellPrice
                                ? up
                                : down
                            }
                            alt={
                              item.USDSellPrice < item.USDPreSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          <div
                            className={`title-9 ${
                              item?.change !== "0"
                                ? item?.change.includes("-")
                                  ? "red-rgba"
                                  : "green-rgba"
                                : item.USDSellPrice < item.USDPreSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {item?.change !== "0"
                              ? item?.change
                              : calculatePercentageDifference(
                                  item.USDSellPrice,
                                  item.USDPreSellPrice
                                )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                        onClick={() => handleDiagramClick(item)}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {chartData && <Chart data={chartData} type="currency" />}
      </Modal>
    </div>
  );
};

export default CurrencyDollarReverseTable;
