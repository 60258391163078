import "./App.css";

import { Routes, Route } from "react-router-dom";

import AboutUsPage from "./pages/AboutUsPage";
import HomePage from "./pages/HomePage";
import ExchangeRatePage from "./pages/ExchangeRatePage";
import ContactUsPage from "./pages/ContactUsPage";
import Header from "./components/Header";
import Footer from "./components/Footer";

import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import ChartPage from "./pages/ChartPage";

const getCurrencyName = (symbol_id) => {
  const nameMap = {
    COINBASE_SPOT_BTC_USD: "Bitcoin",
    COINBASE_SPOT_ETH_USD: "Ethereum",
    BINANCEFTSC_PERP_BNB_USD: "BNB",
    CRYPTOCOM_SPOT_USDT_USD: "USDT",
    COINBASE_SPOT_SOL_USD: "SOL",
    COINJAR_SPOT_USDC_USD: "USDC",
    COINBASE_SPOT_XRP_USD: "XRP",
    COINBASE_SPOT_DOGE_USD: "DOGE",
    BINANCEFTSC_PERP_TRX_USD: "TRX",
    COINBASE_SPOT_ADA_USD: "ADA",
    COINBASE_SPOT_SHIB_USD: "SHIB",
    COINBASE_SPOT_WBTC_USD: "WBTC",
    COINBASE_SPOT_LINK_USD: "LINK",
    COINBASE_SPOT_BCH_USD: "BCH",
    // Add more mappings as necessary
  };

  return nameMap[symbol_id] || null; // Return null for unknown currencies
};

const getCurrencySymbol = (symbol_id) => {
  const symbolMap = {
    COINBASE_SPOT_BTC_USD: "BTC",
    COINBASE_SPOT_ETH_USD: "ETH",
    BINANCEFTSC_PERP_BNB_USD: "BNB",
    CRYPTOCOM_SPOT_USDT_USD: "USDT",
    COINBASE_SPOT_SOL_USD: "SOL",
    COINJAR_SPOT_USDC_USD: "USDC",
    COINBASE_SPOT_XRP_USD: "XRP",
    COINBASE_SPOT_DOGE_USD: "DOGE",
    BINANCEFTSC_PERP_TRX_USD: "TRX",
    COINBASE_SPOT_ADA_USD: "ADA",
    COINBASE_SPOT_SHIB_USD: "SHIB",
    COINBASE_SPOT_WBTC_USD: "WBTC",
    COINBASE_SPOT_LINK_USD: "LINK",
    COINBASE_SPOT_BCH_USD: "BCH",
    // Add more mappings as necessary
  };

  return symbolMap[symbol_id] || null; // Return null for unknown symbols
};

const App = () => {
  const { REACT_APP_URL } = process.env;
  const isToggledFromStore = useSelector((state) => state.isToggled);

  // states
  const [currencies, setCurrencies] = useState([]);
  const [cryptoCurrencies, setcryptoCurrencies] = useState([]);
  const [tomanCurrencies, settomanCurrencies] = useState([]);
  const [golds, setGolds] = useState([]);
  const [coins, setCoins] = useState([]);
  const [noCategories, setNoCategories] = useState([]);
  const [remittances, setRemittances] = useState([]);
  const [announcements, setAnnouncements] = useState("");

  const [tryCurrencies, setTryCurrencies] = useState({});
  const [metalsAndCommodities, setMetalsAndCommodities] = useState([]);
  const [otherCommodities, setOtherCommodities] = useState({});

  useEffect(() => {
    const socket = io(`${REACT_APP_URL}`, {
      transports: ["polling"],
    });

    socket.on("getCurrencies", async (data) => {
      const _data = data.currencies;
      setCurrencies(_data);
    });

    socket.on("getTomanCurrencies", async (data) => {
      const _data = data.currencies;
      settomanCurrencies(_data);
    });

    socket.on("getCoins", (data) => {
      const _data = data.goldInTomans;
      setGolds(_data);
    });

    socket.on("getAnnouncement", (data) => {
      const _data = data.announcement;
      setAnnouncements(_data);
    });

    socket.on("marketDataUpdate", (data) => {
      const _data = data.marketData;

      // Group 1: TRY currencies
      const tryCurrenciesData = [
        _data.find((item) => item.uuid === "CURRENCYCOM_SPOT_USD_TRY"),
        _data.find((item) => item.uuid === "CURRENCYCOM_SPOT_EUR_TRY"),
        _data.find((item) => item.uuid === "CURRENCYCOM_SPOT_GBP_TRY"),
      ];

      // Group 2: Metals and Commodities
      const otherCommoditiesData = [
        "YOBIT_SPOT_CL_USD",
        "BITFINEX_PERP_UKOIL_USDT",
        "CURRENCYCOM_SPOT_GOLD_USD",
        // "YOBIT_SPOT_GOLD_USD",
        "GATEIO_SPOT_GOLDMINER_USDT",
        "CURRENCYCOM_SPOT_SILVER_USD",
        "CURRENCYCOM_SPOT_COPPER_USD",
        // "YOBIT_SPOT_XAU_USD",
        "DEMARC0_SPOT_XAU_USD",
        "DEMARC0_SPOT_XAG_USD",
        "YOBIT_SPOT_XPTX_USD",
        "BITFINEX_PERP_XPT_USDT",
        // "YOBIT_SPOT_XPD_USD",
        "BITFINEX_PERP_XPD_USDT",
      ].map((symbol) => _data.find((item) => item.uuid === symbol));

      // Group 3: Other Commodities
      const metalsAndCommoditiesData = [
        _data.find((item) => item.uuid === "CURRENCYCOM_SPOT_GOLD_USD"),
      ];

      // Set the state
      setTryCurrencies(tryCurrenciesData);
      setMetalsAndCommodities(metalsAndCommoditiesData);
      setOtherCommodities(otherCommoditiesData);
      // console.log(tryCurrenciesData);
      // console.log(metalsAndCommoditiesData);
      // console.log(otherCommoditiesData);
      setNoCategories(_data);
    });

    socket.on("getCurrencyAPI", (data) => {
      const _data = data.marketData;
      setCurrencies(_data);
    });

    socket.on("getGoldInTomanAPI", (data) => {
      const _data = data.marketData;

      setGolds(_data);
    });

    socket.on("sekeTehranDataUpdate", (data) => {
      const _data = data.marketData;
      setCoins(_data);
    });

    socket.on("getCryptoCurrencyAPI", (data) => {
      const _data = data.marketData;
      setcryptoCurrencies(_data);
    });

    socket.on("getNoCategoryAPI", (data) => {
      const _data = data.marketData;
      setNoCategories(_data);
    });

    const getData = async () => {
      try {
        const currRes = await fetch(`${REACT_APP_URL}/currency`);
        const currData = await currRes?.json();
        setCurrencies(currData.currencies);

        const cryptoCurrRes = await fetch(`${REACT_APP_URL}/currency/crypto`);
        const cryptoCurrData = await cryptoCurrRes?.json();
        setcryptoCurrencies(cryptoCurrData.currencies);

        const tomanCurrRes = await fetch(`${REACT_APP_URL}/currency/toman`);
        const tomanCurrData = await tomanCurrRes?.json();
        settomanCurrencies(tomanCurrData.currencies);

        const goldRes = await fetch(`${REACT_APP_URL}/gold`);
        const goldData = await goldRes?.json();
        setGolds(goldData.goldInTomans);

        const announceRes = await fetch(`${REACT_APP_URL}/announcement`);
        const anounceData = await announceRes?.json();
        setAnnouncements(anounceData.announcements);

        const coinRes = await fetch(`${REACT_APP_URL}/coin`);
        const coinData = await coinRes?.json();
        setCoins(coinData.coins);

        const noCategoryRes = await fetch(`${REACT_APP_URL}/no-category`);
        const noCategoryData = await noCategoryRes?.json();

        setNoCategories(noCategoryData.withoutCategories);

        const remittanceRes = await fetch(`${REACT_APP_URL}/remittance`);
        const remittanceData = await remittanceRes?.json();
        setRemittances(remittanceData.remittances);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, []);

  return (
    <div className={`App ${isToggledFromStore === false ? "darkmode" : ""}`}>
      {/* Header Section */}
      <Header />
      <Routes>
        <Route
          path="/"
          exact
          element={
            <HomePage
              cryptoCurrencies={cryptoCurrencies}
              tomanCurrencies={tomanCurrencies}
              currencies={currencies}
              golds={golds}
              coins={coins}
              noCategories={noCategories}
              remittances={remittances}
              announcements={announcements}
              tryCurrencies={tryCurrencies}
              metalsAndCommodities={metalsAndCommodities}
              otherCommodities={otherCommodities}
            />
          }
        />
        <Route
          path="/exchange-rate"
          element={
            <ExchangeRatePage
              cryptoCurrencies={cryptoCurrencies}
              tomanCurrencies={tomanCurrencies}
              currencies={currencies}
              golds={golds}
              coins={coins}
              noCategories={noCategories}
              remittances={remittances}
              tryCurrencies={tryCurrencies}
              metalsAndCommodities={metalsAndCommodities}
              otherCommodities={otherCommodities}
            />
          }
        />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/chart/:table/:type/:id" element={<ChartPage />} />
      </Routes>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default App;
