import React, { useState } from "react";
import Modal from "../Modal";
import Chart from "../Chart";
import up from "../../assets/icons/up.svg";
import down from "../../assets/icons/down.svg";
import diagram from "../../assets/icons/diagram.svg";
import darkDiagram from "../../assets/icons/dark-diagram.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { use } from "react";

// Helper functions
const calculatePercentageDifference = (currentPrice, previousPrice) => {
  if (previousPrice === 0 || !currentPrice || !previousPrice) return 0;
  return (((currentPrice - previousPrice) / previousPrice) * 100).toFixed(2);
};

const formatTime = (date) =>
  date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

const formatDate = (date) =>
  date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });

const CurrencyLiraTable = ({ currencies, tryCurrencies, currentTime }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [TRYRates, setTRYRates] = useState({});
  const isToggledFromStore = useSelector((state) => state.isToggled);

  const navigate = useNavigate();

  const handleDiagramClick = (item) => {
    setChartData(item);
    // setIsModalOpen(true);
    navigate(`/chart/currency/TRY/${item.id}`, {
      state: { data: currencies, item },
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setChartData(null);
  };

  return (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(currentTime)} -</div>
        <div className="title-5">{formatTime(currentTime)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Currency / TRY
                </div>
                <div className="g">
                  <div className="title-5">{formatDate(currentTime)} -</div>
                  <div className="title-5">{formatTime(currentTime)}</div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              {Array.isArray(currencies) &&
                Object.keys(tryCurrencies).length === 0 &&
                currencies?.map((item, index) => (
                  <div className="item" key={`${item.name}-${index}`}>
                    <div className="g-3">
                      <div className="title-7">
                        {item.name}
                        <span className=""> /TRY</span>
                      </div>
                      {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                    </div>
                    <div className="g-2">
                      <div className="text">
                        {item?.TRYBuyRateChange.includes("-")
                          ? item.TRYBuyPrice <= item?.TRYBuyRateChange
                            ? item.TRYBuyPrice
                            : (
                                item?.TRYBuyPrice -
                                parseFloat(
                                  item?.TRYBuyRateChange.replace("-", "")
                                )
                              ).toLocaleString()
                          : (
                              item?.TRYBuyPrice +
                              parseFloat(
                                item?.TRYBuyRateChange.replace("-", "")
                              )
                            ).toLocaleString()}
                      </div>
                      <div className="text">
                        {item.TRYSellRateChange.includes("-")
                          ? item.TRYSellPrice <= item?.TRYSellRateChange
                            ? item.TRYSellPrice
                            : (
                                item?.TRYSellPrice -
                                parseFloat(
                                  item?.TRYSellRateChange.replace("-", "")
                                )
                              ).toLocaleString()
                          : (
                              item?.TRYSellPrice +
                              parseFloat(
                                item?.TRYSellRateChange.replace("-", "")
                              )
                            ).toLocaleString()}
                      </div>
                      <div className="text-icon">
                        <img
                          src={
                            item?.change !== "0"
                              ? item?.change.includes("-")
                                ? down
                                : up
                              : item.TRYSellPrice > item?.TRYPreSellPrice
                              ? up
                              : down
                          }
                          alt={
                            item.TRYSellPrice > item.TRYPreSellPrice
                              ? "Uptrend"
                              : "Downtrend"
                          }
                        />
                        <div
                          className={`title-9 ${
                            item?.change !== "0"
                              ? item?.change.includes("-")
                                ? "red-rgba"
                                : "green-rgba"
                              : item.TRYSellPrice > item.TRYPreSellPrice
                              ? "green-rgba"
                              : "red-rgba"
                          }`}
                        >
                          {item?.change !== "0"
                            ? item?.change
                            : calculatePercentageDifference(
                                item.TRYSellPrice,
                                item.TRYPreSellPrice
                              )}
                          %
                        </div>
                      </div>
                    </div>
                    <img
                      className="ml-2"
                      src={!isToggledFromStore ? darkDiagram : diagram}
                      alt="Trend diagram"
                      onClick={() => handleDiagramClick(item)}
                    />
                  </div>
                ))}
              {Array.isArray(tryCurrencies) &&
                tryCurrencies?.map((item, index) => (
                  <div className="item" key={`${item?.name}-${index}`}>
                    <div className="g-3">
                      <div className="title-7">
                        {item?.name}
                        <span className=""> /TRY</span>
                      </div>
                      {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                    </div>
                    <div className="g-2">
                      <div className="text">
                        {TRYRates["TRY" + item?.symbol]
                          ?.toFixed(2)
                          .toLocaleString() || item?.buyPrice?.toLocaleString()}
                      </div>
                      <div className="text">
                        {item?.sellPrice?.toLocaleString()}
                      </div>
                      <div className="text-icon">
                        <img
                          src={item?.sellPrice > item?.preSellPrice ? up : down}
                          alt={
                            item?.sellPrice > item?.preSellPrice
                              ? "Uptrend"
                              : "Downtrend"
                          }
                        />
                        <div
                          className={`title-9 ${
                            item?.sellPrice > item?.preSellPrice
                              ? "green-rgba"
                              : "red-rgba"
                          }`}
                        >
                          {item?.change !== "0"
                            ? item?.change
                            : calculatePercentageDifference(
                                item?.sellPrice,
                                item?.preSellPrice
                              )}
                          %
                        </div>
                      </div>
                    </div>
                    <img
                      className="ml-2"
                      src={!isToggledFromStore ? darkDiagram : diagram}
                      alt="Trend diagram"
                      onClick={() => handleDiagramClick(item)}
                    />
                  </div>
                )) &&
                currencies
                  ?.filter(
                    (item) => item.id !== 1 || item.id !== 2 || item.id !== 3
                  )
                  .map((item, index) => (
                    <div className="item" key={`${item.name}-${index}`}>
                      <div className="g-3">
                        <div className="title-7">
                          {item.name}
                          <span className=""> /TRY</span>
                        </div>
                        {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {TRYRates["TRY" + item.symbol]
                            ?.toFixed(2)
                            .toLocaleString() ||
                            item?.TRYBuyPrice?.toLocaleString()}
                        </div>
                        <div className="text">
                          {item.TRYSellPrice?.toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              item.TRYSellPrice > item?.TRYPreSellPrice
                                ? up
                                : down
                            }
                            alt={
                              item.TRYSellPrice > item.TRYPreSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          <div
                            className={`title-9 ${
                              item.TRYSellPrice > item.TRYPreSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {item?.change !== "0"
                              ? item?.change
                              : calculatePercentageDifference(
                                  item.TRYSellPrice,
                                  item.TRYPreSellPrice
                                )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                        onClick={() => handleDiagramClick(item)}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {chartData && <Chart data={chartData} type="currency" />}
      </Modal>
    </div>
  );
};

export default CurrencyLiraTable;
