import React, { useEffect, useState } from "react";
import "./Chart.scss";

import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Title,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment-jalaali";
import { _limitValue } from "chart.js/helpers";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Title,
  Legend
);

const Chart = ({ data, type }) => {
  const { REACT_APP_URL } = process.env;
  const [archive, setArchive] = useState([]);

  useEffect(() => {
    const getArchive = async () => {
      const res = await fetch(`${REACT_APP_URL}/archive`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: type,
          id: data.id,
        }),
      });

      const resData = await res.json();

      setArchive(resData?.archive?.reverse());
    };

    getArchive();
  }, [REACT_APP_URL, type, data.id]);

  const chartData = {
    labels: archive?.map((item) =>
      moment(item.createdAt).format("HH:mm jMM/jDD")
    ),
    datasets: [

      {
        label: "نرخ فروش",
        data: archive?.map((item) => item.sellPrice),
        borderColor: data?.sellPrice > data?.pSellPrice ? "#91A600" : "#DE764B",
        backgroundColor:
          data?.sellPrice > data?.pSellPrice ? "#BAE066" : "#F5AD90",
        tension: 0.4,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },

    },
    scales: {
      x: {
        type: "category",
        ticks: {
          font: {
            size: 8, // Adjust the font size for the X-axis labels
          },
          color: "#666", // Optional: Set the font color
        },

      },
      y: {
        type: "linear",
        ticks: {
          font: {
            size: 8, // Adjust the font size for the Y-axis labels
          },
          color: "#666", // Optional: Set the font color
        },

      },
    },
  };

  return (
    <div className="mini-chart-container">
      <Line data={chartData} options={chartOptions} className="chart-canvas" />
    </div>
  );
};

export default Chart;
