import "./Sidebar.scss";
import React, { Fragment } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import cancelIcon from "../../assets/icons/x.svg";
import logo from "../../assets/images/new-logo.png";
import darkLogo from "../../assets/images/new-dark-logo.png";
import activeIcon from "../../assets/icons/indicator.svg";
import { useSelector } from "react-redux";

const Sidebar = ({ showSidebar, setShowSidebar }) => {
  const isToggledFromStore = useSelector((state) => state.isToggled);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <Fragment>
      <svg
        onClick={() => setShowSidebar(!showSidebar)}
        className="side-icon z-30 items-center cursor-pointer m-2 mr-0 ml-4 xm:w-[20px] xlg:w-[40px] absolute right-3"
        fill={!isToggledFromStore ? "#fff" : "#252B2B"}
        viewBox="0 0 100 80"
        width="28"
        height="28"
      >
        <rect width="100" height="12"></rect>
        <rect y="30" width="100" height="12"></rect>
        <rect y="60" width="100" height="12"></rect>
      </svg>

      <div
        className={`sidebar-con top-0 right-0 w-[86%] h-screen bg-[#fff] py-[1.5rem] text-red fixed z-40 ${
          showSidebar
            ? "transition duration-1000 ease-in-out translate-x-0"
            : "transition duration-1000 ease-in-out translate-x-full"
        }`}
      >
        <div className="sidebar-header flex justify-between items-center">
          <img
            className="w-[4.5rem] cursor-pointer"
            src={!isToggledFromStore ? darkLogo : logo}
            alt="logo"
            onClick={() => handleLogoClick()}
          />
          <button
            className="flex text-3xl items-center cursor-pointer z-50"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <img
              className="w-[1.5rem] h-5 my-3"
              width={16}
              height={16}
              src={cancelIcon}
              alt="cancelIcon"
            />
          </button>
        </div>
        <ul className="side-menu flex flex-col justify-evenly items-start text-[16px] text-black duration-700 font-bold">
          <NavLink
            onClick={() => setShowSidebar(!showSidebar)}
            className={`flex items-center ${
              location.pathname === "/" ? "text-[#1775ee]" : ""
            }`}
            to="/"
          >
            <li className="flex items-center">
              {location.pathname === "/" && (
                <img src={activeIcon} alt="active" className="ml-2 w-2 h-2" />
              )}
              Home
            </li>
          </NavLink>

          <NavLink
            onClick={() => setShowSidebar(!showSidebar)}
            className={`flex items-center ${
              location.pathname === "/exchange-rate" ? "text-[#1775ee]" : ""
            }`}
            to="/exchange-rate"
          >
            <li className="flex items-center">
              {location.pathname === "/exchange-rate" && (
                <img src={activeIcon} alt="active" className="ml-2 w-2 h-2" />
              )}
              Exchange Rate
            </li>
          </NavLink>

          <NavLink
            onClick={() => setShowSidebar(!showSidebar)}
            className={`flex items-center ${
              location.pathname === "/about-us" ? "text-[#1775ee]" : ""
            }`}
            to="/about-us"
          >
            <li className="flex items-center">
              {location.pathname === "/about-us" && (
                <img src={activeIcon} alt="active" className="ml-2 w-2 h-2" />
              )}
              About us
            </li>
          </NavLink>

          <NavLink
            onClick={() => setShowSidebar(!showSidebar)}
            className={`flex items-center ${
              location.pathname === "/contact-us" ? "text-[#1775ee]" : ""
            }`}
            to="/contact-us"
          >
            {location.pathname === "/contact-us" && (
              <img src={activeIcon} alt="active" className="ml-2 w-2 h-2" />
            )}
            <li className="flex items-center">Contact us</li>
          </NavLink>
        </ul>
      </div>
    </Fragment>
  );
};

export default Sidebar;
