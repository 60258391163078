import React, { useEffect, useState } from "react";
import "../../styles/Home.scss";

import downArrow from "../../assets/icons/keyboard_arrow_down.svg";
import usdFlag from "../../assets/flags/USD.svg";
import tryFlag from "../../assets/flags/TRY.svg";
import goldFlag from "../../assets/flags/metal-gold.svg";
import goldCoinFlag from "../../assets/flags/coin-gold.svg";
import silverFlag from "../../assets/flags/metal-silver.svg";
import silverCoinFlag from "../../assets/flags/coin-silver.svg";
import btcFlag from "../../assets/flags/BTC.svg";
import irrFlag from "../../assets/flags/IRR.svg";
import EURFlag from "../../assets/flags/EUR.svg";
import AEDFlag from "../../assets/flags/AED.svg";
import CNYFlag from "../../assets/flags/CNY.svg";
import GBPFlag from "../../assets/flags/GBP.svg";
import ETHFlag from "../../assets/flags/ETH.svg";
import BNBFlag from "../../assets/flags/BNB.svg";
import USDTFlag from "../../assets/flags/USDT.svg";
import SOLFlag from "../../assets/flags/SOL.svg";
import USDCFlag from "../../assets/flags/USDC.svg";
import XRPFlag from "../../assets/flags/XRP.svg";
import DOGEFlag from "../../assets/flags/DOGE.svg";
import TRXFlag from "../../assets/flags/TRX.svg";
import ADAFlag from "../../assets/flags/ADA.svg";
import SHIBFlag from "../../assets/flags/SHIB.svg";
import WBTCFlag from "../../assets/flags/WBTC.svg";
import LINKFlag from "../../assets/flags/LINK.svg";
import BCHFlag from "../../assets/flags/BCH.svg";

const flagMap = {
  USD: usdFlag,
  TRY: tryFlag,
  IRR: irrFlag,
  EUR: EURFlag,
  AED: AEDFlag,
  CNY: CNYFlag,
  GBP: GBPFlag,
  BTC: btcFlag,
  ETH: ETHFlag,
  BNB: BNBFlag,
  USDT: USDTFlag,
  SOL: SOLFlag,
  USDC: USDCFlag,
  XRP: XRPFlag,
  DOGE: DOGEFlag,
  TRX: TRXFlag,
  ADA: ADAFlag,
  SHIB: SHIBFlag,
  WBTC: WBTCFlag,
  LINK: LINKFlag,
  BCH: BCHFlag,
  Gold: goldFlag,
  Silver: silverFlag,
  GoldCoin: goldCoinFlag,
  SilverCoin: silverCoinFlag,
  // Add more mappings here as necessary
};

const Converter = ({ tomanCurrencies, currencies, golds, crypto }) => {
  const [all, setAll] = useState([]);
  const [fromCurrency, setFromCurrency] = useState(null); // Initially set to null
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [amount, setAmount] = useState(1.0); // Default amount
  const [conversionRates, setConversionRates] = useState({
    usd: 0,
    lira: 0,
    gold: 0,
    bitcoin: 0,
  });

  // Combine all currencies, golds, and crypto into one array
  useEffect(() => {
    const combinedData = [...currencies, ...golds, ...crypto];
    setAll(combinedData);

    // Only set the default fromCurrency when the currencies array is non-empty
    if (currencies.length > 0 && !fromCurrency) {
      setFromCurrency(currencies[0]); // Set default to the first currency
    }
  }, [currencies, golds, crypto, fromCurrency]);

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Function to select a currency and calculate conversions
  const selectCurrency = (currency) => {
    setFromCurrency(currency); // Update selected currency
    setIsDropdownOpen(false);

    let selectedRate;
    if (
      currency?.symbol === "BTC" ||
      currency?.symbol === "ETH" ||
      currency?.symbol === "BNB" ||
      currency?.symbol === "USDT" ||
      currency?.symbol === "SOL" ||
      currency?.symbol === "USDC" ||
      currency?.symbol === "XRP" ||
      currency?.symbol === "DOGE" ||
      currency?.symbol === "TRX" ||
      currency?.symbol === "ADA" ||
      currency?.symbol === "SHIB" ||
      currency?.symbol === "WBTC" ||
      currency?.symbol === "LINK" ||
      currency?.symbol === "BCH"
    ) {
      // Conversion logic based on Istanbul buy price for crypto
      selectedRate = currency?.buyPrice;
    } else if (currency.istanbulBuyPrice) {
      selectedRate =
        currency?.istanbulBuyPrice /
        currencies.find((c) => c.symbol === "USD")?.TRYBuyPrice;

    } else {
      selectedRate = currency?.USDBuyPrice;
    }

    const usdRate =
      selectedRate / currencies.find((c) => c.symbol === "USD")?.USDBuyPrice;
    const liraRate =
      usdRate *
      (tomanCurrencies.find((c) => c.symbol === "USD")?.istanbulBuyPrice /
        tomanCurrencies.find((c) => c.symbol === "TRY")?.istanbulBuyPrice);
    const goldRate =
      selectedRate /
      golds.find((g) => g.name.toLowerCase().includes("ons altın"))
        ?.istanbulBuyPrice;

    const bitcoinRate =
      selectedRate / crypto.find((c) => c.name === "Bitcoin")?.buyPrice;

    setConversionRates({
      usd: usdRate,
      lira: liraRate,
      gold: goldRate,
      bitcoin: bitcoinRate,
    });
  };

  // Handle amount input change
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  // Function to format the number to always display 5 digits
  const formatNumber = (value) => {
    const strValue = value.toString();
    const [integerPart, decimalPart] = strValue.split(".");

    if (integerPart.length >= 5) {
      // If the integer part already has 5 or more digits, return it as-is
      return parseInt(integerPart).toFixed(0);
    } else {
      // Otherwise, calculate how many decimal places to keep
      const decimalPlaces = 5 - integerPart.length;
      return value.toFixed(decimalPlaces);
    }
  };

  return (
    <div className="gradient-border2">
      <div className="converter">
        <div className="text-fields">
          <div className="unit">
            <div className="text-field">
              <div className="title">Amount</div>
              <div className="fied">
                <div className="content">
                  <input
                    className="text-wrapper"
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    placeholder="$1.00"
                  />
                </div>
              </div>
            </div>
            <div className="text-field">
              <div className="title">From</div>
              <div className="fied" onClick={toggleDropdown}>
                <div className="content">
                  <div className="icon-text">
                    <div className="text-wrapper flex items-center">
                      {fromCurrency?.symbol && (
                        <img
                          src={
                            fromCurrency.symbol === "SDR"
                              ? require(`../../assets/flags/${fromCurrency.symbol}.png`)
                              : require(`../../assets/flags/${fromCurrency.symbol}.svg`)
                          }
                          alt={`${fromCurrency.name} flag`}
                          className="mr-1"
                        />
                      )}
                      {fromCurrency?.name.toLowerCase().includes("altın") && (
                        <img src={goldFlag} alt="gold" className="mr-1" />
                      )}
                      {fromCurrency?.name.toLowerCase().includes("Gümüş") && (
                        <img src={silverFlag} alt="silver" className="mr-1" />
                      )}
                      {fromCurrency?.name}
                    </div>
                  </div>
                  <img
                    className={`img ${isDropdownOpen ? "rotate-arrow" : ""}`}
                    alt="Keyboard arrow down"
                    src={downArrow}
                  />
                </div>
                {isDropdownOpen && (
                  <div className="dropdown">
                    <div className="dropdown-inner">
                      {all?.map((item) => (
                        <div
                          key={item.name}
                          className="dropdown-item"
                          onClick={() => selectCurrency(item)}
                        >
                          <div className="icon-text">
                            <div className="img">
                              {item.symbol && (
                                <img
                                  src={
                                    item.symbol === "SDR"
                                      ? require(`../../assets/flags/${item.symbol}.png`)
                                      : require(`../../assets/flags/${item.symbol}.svg`)
                                  }
                                  alt={`${item.name} flag`}
                                />
                              )}
                              {item?.name.toLowerCase().includes("altın") ||
                              item?.name.toLowerCase().includes("ons eur") ||
                              item?.name.toLowerCase().includes("ayar bilezik") ? (
                                <img src={goldFlag} alt="gold" />
                              ) : item?.name.toLowerCase().includes("gümüş") ? (
                                <img src={silverFlag} alt="gold" />
                              ) : item?.name.toLowerCase().includes("reşat") ? (
                                <img src={goldCoinFlag} alt="gold" />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="text-wrapper">{item.name}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <button
            className="button"
            onClick={() => selectCurrency(fromCurrency)}
          >
            <div className="div">Convert</div>
          </button>
        </div>
        <div className="result-button">
          <div className="result">
            <div className="title-2">
              {amount} {fromCurrency?.name} =
            </div>
            <div className="icon-text-2">
              <div className="flag-frame">
                <img src={usdFlag} alt="USD Flag" />
              </div>
              <div className="title-3">
                {formatNumber(amount * conversionRates.usd)} US Dollars
              </div>
            </div>
          </div>

          <div className="result">
            <div className="title-2">
              {amount} {fromCurrency?.name} =
            </div>
            <div className="icon-text-2">
              <div className="flag-frame">
                <img src={tryFlag} alt="TRY Flag" />
              </div>
              <div className="title-3">
                {formatNumber(amount * conversionRates.lira)} Lira
              </div>
            </div>
          </div>

          <div className="result">
            <div className="title-2">
              {amount} {fromCurrency?.name} =
            </div>
            <div className="icon-text-2">
              <div className="flag-frame">
                <img src={goldFlag} alt="Gold Symbol" />
              </div>
              <div className="title-3">
                {formatNumber(amount * conversionRates.gold)} Ounce
              </div>
            </div>
          </div>

          <div className="result">
            <div className="title-2">
              {amount} {fromCurrency?.name} =
            </div>
            <div className="icon-text-2">
              <div className="flag-frame">
                <img src={btcFlag} alt="Bitcoin Symbol" />
              </div>
              <div className="title-3">
                {formatNumber(amount * conversionRates.bitcoin)} Bitcoin
              </div>
            </div>
          </div>

          <div className="result">
            <div className="title-2">
              {amount} {fromCurrency?.name} =
            </div>
            <div className="icon-text-2">
              <div className="flag-frame">
                <img src={irrFlag} alt="IRR Flag" />
              </div>
              <div className="title-3">
                {formatNumber(
                  amount *
                    conversionRates.usd *
                    tomanCurrencies.find((c) => c.symbol === "USD")
                      ?.istanbulBuyPrice
                )}{" "}
                IRR
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Converter;
