import React, { Fragment, useState } from "react";
import "../styles/ContactUs.scss";

import leftD from "../assets/images/right-design.png";
import rightD from "../assets/images/left-design.png";
import phone from "../assets/icons/phone.svg";
import mapPin from "../assets/icons/map-pin.svg";
import indicator from "../assets/icons/indicator.svg";
import telegram from "../assets/icons/telegram2.svg";
import instagram from "../assets/icons/instagram2.svg";
import twitter from "../assets/icons/twitter.svg";
import whatsapp from "../assets/icons/whatsappp2.svg";

import miniLeftD from "../assets/images/mini-left-design.png";
import miniRightD from "../assets/images/mini-right-design.png";

const ContactUsPage = () => {
  const { REACT_APP_URL } = process.env;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const submitFeedback = async () => {
    setIsUpdating(true);
    setErrorMessage(""); // Clear any previous error

    try {
      const response = await fetch(`${REACT_APP_URL}/feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          message,
        }),
      });

      const result = await response.json();
      
      if (response.ok) {
      } else {
        throw new Error(result.message || "Failed to submit feedback.");
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="contact-us-container">
      <div className="main-header">
        <img src={miniLeftD} className="hidden max-[600px]:block" />
        <img src={rightD} className="max-[600px]:hidden" />
        <h1>Contact Us</h1>
        <img src={miniRightD} className="hidden max-[600px]:block" />
        <img src={leftD} className="max-[600px]:hidden" />
      </div>

      <div className="gradient-border4">
        <div className="contact-container !px-[3.5rem] !py-[3rem] max-[500px]:!px-[1rem]">
          <div className="title feed-title">
            <span>Drop Us a Line</span>
          </div>

          <div className="feedback">
            <div className="feedback-item desc">
              <div>
                We value your input and are always here to listen to your
                feedback, suggestions, and questions. If you need assistance,
                additional information, or just want to share your thoughts,
                please don’t hesitate to reach out. Simply fill out the contact
                form below, and our team will get back to you promptly. You can
                also use the channels listed to connect with us directly.
              </div>
              <div>
                <div className="font-bold">Our Social media channels:</div>
                <div className="social w-[50%] flex justify-between">
                  <a href="https://instagram.com/mrdolarfarsi">
                    <img src={instagram} />
                  </a>
                  <a href="">
                    <img src={telegram} />
                  </a>
                  <a href="https://wa.me/+905445562283">
                    <img src={whatsapp} />
                  </a>
                </div>
              </div>
            </div>
            <div className="feedback-item form">
              <div className="w-[100%] flex flex-col gap-[1.5rem]">
                <div className="pass-item">
                  <label>Name</label>
                  <input
                    required
                    placeholder="Enter your name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="pass-item">
                  <label>Email</label>
                  <input
                    required
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="pass-item">
                  <label>Message</label>
                  <textarea
                    required
                    placeholder="Type here"
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </div>
              <div className="submit-btn">
                <button onClick={submitFeedback} disabled={isUpdating}>
                  {isUpdating
                    ? "Your message is sending..."
                    : "Consent message"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gradient-border4">
        <div className="contact-container">
          <div className="title">
            <span>Get in touch with us</span>{" "}
          </div>
          <div className="content">
            We are always ready to hear your feedback, suggestions, and
            questions. If you need guidance or more information, please feel
            free to reach out to us through the following channels:
          </div>
          <div className="contanct-items">
            <div className="item">
              <div className="flex font-bold text-[1.125rem] items-center justify-between">
                <img className="mx-[0.75rem]" src={indicator} />
                Phone number
                <img className="mx-[0.75rem]" src={indicator} />
              </div>
              <div className="values">
                <span>
                  <img src={phone} alt="phone" /> +902125705959
                </span>
                <span>
                  <img src={phone} alt="phone" /> +905445562282-3
                </span>
              </div>
            </div>
            <div className="item">
              <div className="flex font-bold text-[1.125rem]">
                <img className="mx-[0.75rem]" src={indicator} />
                Addresses
                <img className="mx-[0.75rem]" src={indicator} />
              </div>
              <div className="values">
                <span>
                  <img src={mapPin} alt="map-pin" /> Istanbul, Beylikdüzü
                  Innovıa 1 Karşı
                </span>
                <span>
                  <img src={mapPin} alt="map-pin" /> Istanbul, Beylikdüzü
                  Innovıa 3
                </span>
                <span>
                  <img src={mapPin} alt="map-pin" /> Istanbul, Kapalı çarşı
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
