import React, { useEffect, useState } from "react";
import "../styles/ExchangeRate.scss";

import dropdown from "../assets/icons/dropdown.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import WorldClocks from "../components/WorldClocks";
import Modal from "../components/Modal";
import Chart from "../components/Chart";
import CurrencyLiraTable from "../components/Tables/CurrencyLiraTable";
import CurrencyDollarTable from "../components/Tables/CurrencyDollarTable";
import CurrencyDollarReverseTable from "../components/Tables/CurrencyDollarReverseTable";
import CurrencyTomanIstanbulTable from "../components/Tables/CurrencyTomanIstanbulTable";
import CurrencyTomanTehranTable from "../components/Tables/CurrencyTomanTehranTable";
import GoldIstanbulTable from "../components/Tables/GoldIstanbulTable";
import CryptoTable from "../components/Tables/CryptoTable";
import NoCategoryTable from "../components/Tables/NoCategoryTable";
import CoinTable from "../components/Tables/CoinTable";

// Helper function to format time to military (24-hour) format
const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

// Helper function to format date as "DD MMM" without year
const formatDate = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });
};

const ExchangeRatePage = ({
  currencies,
  cryptoCurrencies,
  tomanCurrencies,
  golds,
  coins,
  noCategories,
  remittances,
  tryCurrencies,
  metalsAndCommodities,
  otherCommodities,
}) => {
  const [selectedOption, setSelectedOption] = useState("Currency first");
  const [isOpen, setIsOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date()); // State for current time

  const isToggledFromStore = useSelector((state) => state.isToggled);

  useEffect(() => {
    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (location.state?.selected) {
      setSelectedOption(location.state.selected);
    }
  }, [location.state]);

  const options = ["Gold first", "Currency first", "Crypto currency first"];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="exchange-rate-page">
      <div className="custom-dropdown">
        <div className="gradient-border">
          <div
            className={`dropdown-header ${isOpen ? "open" : ""}`}
            onClick={toggleDropdown}
          >
            <img
              src={dropdown}
              alt="dropdown-background"
              className="dropdown-bg mr-[10px]"
            />
            <span>{selectedOption}</span>
          </div>
        </div>
        {isOpen && (
          <ul
            className={`dropdown-options ${
              !isToggledFromStore ? "dark-drop" : ""
            }`}
          >
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className="dropdown-option"
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="exchange-rate-container">
        {selectedOption === "Gold first" && (
          <>
            <CoinTable coins={coins} currentTime={currentTime} />
            <GoldIstanbulTable golds={golds} currentTime={currentTime} />
            <CurrencyTomanIstanbulTable
              tomanCurrencies={tomanCurrencies}
              currentTime={currentTime}
            />
            <CurrencyTomanTehranTable
              tomanCurrencies={tomanCurrencies}
              currentTime={currentTime}
            />
            <CurrencyLiraTable
              currencies={currencies}
              tryCurrencies={tryCurrencies}
              currentTime={currentTime}
            />
            <CurrencyDollarTable
              currencies={currencies}
              currentTime={currentTime}
            />
            <CurrencyDollarReverseTable
              currencies={currencies}
              currentTime={currentTime}
            />
            <NoCategoryTable
              noCategories={noCategories}
              currentTime={currentTime}
            />
            <CryptoTable
              cryptoCurrencies={cryptoCurrencies}
              currentTime={currentTime}
            />
          </>
        )}
        {selectedOption === "Currency first" && (
          <>
            <CurrencyTomanIstanbulTable
              tomanCurrencies={tomanCurrencies}
              currentTime={currentTime}
            />
            <CurrencyTomanTehranTable
              tomanCurrencies={tomanCurrencies}
              currentTime={currentTime}
            />
            <CurrencyLiraTable
              currencies={currencies}
              tryCurrencies={tryCurrencies}
              currentTime={currentTime}
            />
            <CurrencyDollarTable
              currencies={currencies}
              currentTime={currentTime}
            />
            <CurrencyDollarReverseTable
              currencies={currencies}
              currentTime={currentTime}
            />
            <CoinTable coins={coins} currentTime={currentTime} />
            <GoldIstanbulTable golds={golds} currentTime={currentTime} />
            <NoCategoryTable
              noCategories={noCategories}
              currentTime={currentTime}
            />
            <CryptoTable
              cryptoCurrencies={cryptoCurrencies}
              currentTime={currentTime}
            />
          </>
        )}
        {selectedOption === "Crypto currency first" && (
          <>
            <CryptoTable
              cryptoCurrencies={cryptoCurrencies}
              currentTime={currentTime}
            />
            <CurrencyTomanIstanbulTable
              tomanCurrencies={tomanCurrencies}
              currentTime={currentTime}
            />
            <CurrencyTomanTehranTable
              tomanCurrencies={tomanCurrencies}
              currentTime={currentTime}
            />
            <CurrencyLiraTable
              currencies={currencies}
              tryCurrencies={tryCurrencies}
              currentTime={currentTime}
            />
            <CurrencyDollarTable
              currencies={currencies}
              currentTime={currentTime}
            />
            <CurrencyDollarReverseTable
              currencies={currencies}
              currentTime={currentTime}
            />
            <CoinTable coins={coins} currentTime={currentTime} />
            <GoldIstanbulTable golds={golds} currentTime={currentTime} />
            <NoCategoryTable
              noCategories={noCategories}
              currentTime={currentTime}
            />
          </>
        )}
      </div>
      <WorldClocks />
    </div>
  );
};

export default ExchangeRatePage;